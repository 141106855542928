.App {
  text-align: center;
}

.MuiButtonBase-root {
  text-transform: none !important;
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

.instruction-list__title {
    font-weight: bold;
    font-size: 16px;
}